import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Platform } from '@angular/cdk/platform';
import { TranslateService } from '@ngx-translate/core';
import { Subject, Subscription, throwError } from 'rxjs';
import { catchError, take, takeUntil, tap } from 'rxjs/operators';
import { FuseConfigService } from '@fuse/services/config.service';
import { FuseNavigationService } from '@fuse/components/navigation/navigation.service';
import { FuseSidebarService } from '@fuse/components/sidebar/sidebar.service';
import { FuseSplashScreenService } from '@fuse/services/splash-screen.service';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { locale as navigationEnglish } from 'app/navigation/i18n/en';
import { locale as navigationTurkish } from 'app/navigation/i18n/tr';
import { locale as navigationEspanish } from 'app/navigation/i18n/es';
import { NavigationService } from './navigation/navigation.service';
import { UserService } from './services/pay-in/user.service';
import { Navigation } from './main/models/navigation.model';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { AlertService } from './main/apps/shared/ui/services/alert.service';
import { KeycloakService, KeycloakEventType } from 'keycloak-angular';
import { SidebarRoutes } from './utils/sidebar-routes';
import { InactivityService } from './main/apps/shared/session/services/inactivity.service';
import { MatDialog } from '@angular/material/dialog';
import { InactivityModalComponent } from './main/apps/shared/session/components/inactivity-modal/inactivity-modal.component';

@Component({
    selector: 'app',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
    fuseConfig: any;
    navigation: any;
    listMenu: any;

    showWdiget: boolean = false;

    metadata: Object = {
        merchantId: 'No tiene'
    }

    selectedNameSubscription: Subscription
    url: string;
    // Private
    private _unsubscribeAll: Subject<any>;

    /**
     * Constructor
     *
     * @param {DOCUMENT} document
     * @param {FuseConfigService} _fuseConfigService
     * @param {FuseNavigationService} _fuseNavigationService
     * @param {FuseSidebarService} _fuseSidebarService
     * @param {FuseSplashScreenService} _fuseSplashScreenService
     * @param {FuseTranslationLoaderService} _fuseTranslationLoaderService
     * @param {Platform} _platform
     * @param {TranslateService} _translateService
     */
    constructor(
        @Inject(DOCUMENT) private document: any,
        private _fuseConfigService: FuseConfigService,
        private _fuseNavigationService: FuseNavigationService,
        private _fuseSidebarService: FuseSidebarService,
        private _fuseSplashScreenService: FuseSplashScreenService,
        private _fuseTranslationLoaderService: FuseTranslationLoaderService,
        private _translateService: TranslateService,
        private _platform: Platform,
        private _navigate: NavigationService,
        private router: Router,
        private alertService: AlertService,
        private _userService: UserService,
        private _router: Router,
        private keycloakService: KeycloakService,
        private inactivityService: InactivityService,
        private _matDialog: MatDialog,
    ) {

        
        
        this.sessionInactive()
        // this.keycloakService.getKeycloakInstance().

       this.setUrl()
        
        // Add languages
        this._translateService.addLangs(['en', 'tr', 'es']);

        // Set the default language
        this._translateService.setDefaultLang('es');

        // Set the navigation translations
        this._fuseTranslationLoaderService.loadTranslations(navigationEnglish, navigationTurkish, navigationEspanish);

        // Use a language
        this._translateService.use('es');

        // Add is-mobile class to the body if the platform is mobile
        if (this._platform.ANDROID || this._platform.IOS) {
            this.document.body.classList.add('is-mobile');
        }

        // Set the private defaults
        this._unsubscribeAll = new Subject();
    }

    ngOnInit(): void {


        this.refreshToken();

        this.setMenu();

        this._router.events.subscribe((ev) => {

            if (String(ev['url']) != 'undefined') {
                if (ev['url'] == '/pages/auth/login') {
                    this.showWdiget = true;
                } else {
                    this.showWdiget = false;
                }
            }
        })

        this.selectedNameSubscription = this._userService.merchantSelectedName.asObservable()
            .subscribe((result) => {
                this.metadata = {
                    merchantId: result
                }
            })

        // Subscribe to config changes
        this._fuseConfigService.config
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((config) => {

                this.fuseConfig = config;

                // Boxed
                if (this.fuseConfig.layout.width === 'boxed') {
                    this.document.body.classList.add('boxed');
                } else {
                    this.document.body.classList.remove('boxed');
                }

                // Color theme - Use normal for loop for IE11 compatibility
                for (let i = 0; i < this.document.body.classList.length; i++) {
                    const className = this.document.body.classList[i];

                    if (className.startsWith('theme-')) {
                        this.document.body.classList.remove(className);
                    }
                }

                this.document.body.classList.add(this.fuseConfig.colorTheme);
            });
    }

    sessionInactive() {
        this.inactivityService.inactivityDetected.subscribe(() => {
            console.log('Usuario inactivo. Muestra el popup.');
           
            const dialog = this._matDialog.open(InactivityModalComponent, {
                panelClass: 'event-form-dialog',
                 minHeight: '300px',
                minWidth: '300px',
                disableClose: true,
            });
    
            dialog.afterClosed().subscribe((result) => {
                console.log(result);
                if(!result){
                    this.sessionInactive();
                }
                
                
            });
          });
    }

    setUrl(){
        this.router.events.subscribe((event) => {
            if (event instanceof NavigationEnd) {
              this.url = this.router.url;
            }
          });
    }

    
    setMenu()  {
        // se realiza el filtro de los menues en navigation.compoment.ts createMenuByRole()
        this._fuseNavigationService.register('main', SidebarRoutes);
        this._fuseNavigationService.setCurrentNavigation('main');
    }

    obtenerPerfilUsuario() {
        this.router.navigate(["apps/dashboards/project"]);
        localStorage.setItem('roleType', String(this.keycloakService.getKeycloakInstance().realmAccess['roles'][0]));
        localStorage.setItem('email', String(this.keycloakService.getKeycloakInstance().tokenParsed['email']));
        localStorage.setItem('merchantId', String(this.keycloakService.getKeycloakInstance().tokenParsed['merchantId'][0]))
        sessionStorage.setItem("username", String(this.keycloakService.getKeycloakInstance().tokenParsed['name']))
    }

    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
        this.selectedNameSubscription.unsubscribe()
    }

    /**
     * Toggle sidebar open
     *
     * @param key
     */
    toggleSidebarOpen(key): void {
        this._fuseSidebarService.getSidebar(key).toggleOpen();
    }

   

    refreshToken(){
        this.keycloakService.keycloakEvents$.subscribe((event) => {
            if (event && event.type === KeycloakEventType.OnTokenExpired) {
              this.keycloakService.updateToken();
            }
          });
    }
}
