<div class="dashboard-template">
    <div class="dashboard-template-banner">
        <div>
            <span class="mat-display-1 dashboard-title" *ngIf="title">&nbsp;{{ title }}
            </span>
            <span class="mat-display-1 dashboard-title" *ngIf="translateTitle">&nbsp;{{ translateTitle | translate }}
            </span>
        </div>
        <div class="filters" *ngIf="filters">
            <mat-card>
                <ng-content select="[slot=filters]"></ng-content>
            </mat-card>
        </div>
    </div>
    <div class="content-body">
        <ng-content select="[slot=content]"></ng-content>
    </div>
</div>