import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule, Routes } from '@angular/router';
import { MatMomentDateModule, MomentDateModule } from '@angular/material-moment-adapter';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';
import 'hammerjs';
import { InMemoryWebApiModule } from 'angular-in-memory-web-api';
import { FuseModule } from '@fuse/fuse.module';
import { FuseSharedModule } from '@fuse/shared.module';
import { FuseProgressBarModule, FuseSidebarModule, FuseThemeOptionsModule } from '@fuse/components';

import { fuseConfig } from 'app/fuse-config';
import { AppComponent } from 'app/app.component';
import { LayoutModule } from 'app/layout/layout.module';
import { FormsModule } from '@angular/forms';

import { NgIdleKeepaliveModule } from '@ng-idle/keepalive';
import { UiModule } from './main/apps/shared/ui/ui.module';
import { SupportModule } from './main/apps/shared/support/support.module';
import { FakeDbService } from './fake-db/fake-db.service';
import { KeycloakAngularModule, KeycloakService } from 'keycloak-angular';
import { initializeKeycloak } from './utils/keycloack/keycloack.init';
import { KeycloackAuthGuard } from './utils/keycloack/guard/keycloack-auth.guard';
import { SupportPageComponent } from './main/apps/shared/support/support-page/support-page.component';
import { SessionModule } from './main/apps/shared/session/session.module';

const appRoutes: Routes = [
    {
        path: '',
        loadChildren: () => import('./main/apps/apps.module').then(m => m.AppsModule),
        canActivate: [KeycloackAuthGuard]
    },
    {
    path: "support",
    component: SupportPageComponent
    },
    {
        path: 'pages',
        loadChildren: () => import('./main/pages/pages.module').then(m => m.PagesModule),
        canActivate: [KeycloackAuthGuard]
    },
];

@NgModule({
    declarations: [
        AppComponent
    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        HttpClientModule,
        RouterModule.forRoot(appRoutes),

        TranslateModule.forRoot(),
        MatMomentDateModule,

        InMemoryWebApiModule.forRoot(FakeDbService, {
            delay: 0,
            passThruUnknownUrl: true
        }),

        // Material
        MatButtonModule,
        MatIconModule,

        // Fuse modules
        FuseModule.forRoot(fuseConfig),
        FuseProgressBarModule,
        FuseSharedModule,
        FuseSidebarModule,
        FuseThemeOptionsModule,

        // App modules
        LayoutModule,
        UiModule,
        SupportModule,
        SessionModule,
        //Timer
        FormsModule,
        HttpClientModule,
        MomentDateModule,
        NgIdleKeepaliveModule.forRoot(),
        KeycloakAngularModule,
    ],
    providers: [
        {
            provide: APP_INITIALIZER,
            useFactory: initializeKeycloak,
            multi: true,
            deps: [KeycloakService],
          },
    ],
    bootstrap: [
        AppComponent
    ]
})
export class AppModule {
}
