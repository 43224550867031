import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ConfiguracionUrl } from 'app/utils/configuracionUrl';
import { dashboardCabModel } from 'app/main/models/dashboardModel.model';
import { Observable } from 'rxjs';
import { ListOperationsRequest } from './models/operation/list-operations-request';
import { ListOperationsResponse } from './models/operation/list-operations-response';
import { ReportResponse } from './models/operation/report-response';
import { ListMerchantsResponse } from './models/merchant/list-merchants-response';
import { MerchantBean } from './models/bean/merchant-bean';
import { MerchantKeyRequest } from './models/merchant/merchant-key-request';
import { MerchantGroupBean, GroupBean } from './models/merchant/merchants-group-bean';
import { MerchantAllResponse } from './models/merchant/merchantAllResponse';
import { MerchantGroup } from './models/merchant/merchantGroup';
import { Country } from './models/merchant/country';
import { Currency } from './models/merchant/currency';
import { Processor } from './models/merchant/processor';
import { MerchantRequest } from './models/merchant/createMerchantRequest';
import { MerchantRateRequest } from './models/merchant/addMerchantRateRequest';
import { CustomMerchantList } from './models/merchant/customMerchantList';
import { MerchantRateList } from './models/merchant/merchantRatesList';
import { MerchantRouteRuleBean } from './models/routing/merchant-route-rule-bean';
import { GenericBean } from './models/bean/generic-bean';
import { ModifyMerchantRouteRuleRequest } from './models/routing/modify-merchant-route-rule-request';
import { MasterParametersBean } from './models/bean/master-parameters-bean';
import { MerchantRouteRuleMethodBean, Settings } from './models/routing/merchant-route-rule-method-bean';
import { ModifyMerchantRouteMethodRequest } from './models/routing/modify-merchant-route-method-request';
import { IFindAllMerchantMultiprocessorPaymentMethodsResponse } from './models/merchant/find-all-merchant-multiprocessor-payment-methods-response.interface';
import { environment } from 'environments/environment';
import { IFindAllMerchantMultiprocessorConfig } from 'app/main/interfaces/find-all-merchant-multiprocessor-config.interface';
import { ConfigurationProcessorRequest } from './models/merchant/configurationProcessorRequest.model';
import { KeycloakService } from 'keycloak-angular';

@Injectable({
    providedIn: 'root'
})
export class MerchantService {

    baseUrl: ConfiguracionUrl;
    headers: any;

    constructor(private httpClient: HttpClient, private keycloackService: KeycloakService) {
        this.baseUrl = new ConfiguracionUrl();
        this.headers = new HttpHeaders({
            'Content-type': 'application/json;charset=utf8',
            'Authorization': 'Bearer ' + this.keycloackService.getKeycloakInstance().token
        });
    }


    getAllMerchantsByPage(page: number, size: number): Observable<MerchantAllResponse[]> {
        return this.httpClient.get<MerchantAllResponse[]>(this.baseUrl.getUrlComercios() + `/all?page=${page}&size=${size}`,
            { headers: this.headers }
        )
    };

    // getAllMerchantsCustomize() {
    //     return this.httpClient.get<CustomMerchantList[]>(`${this.baseUrl.getUrlComercios()}/merchant/custom`, { headers: this.headers });
    // }

    getAllMerchantsCustomize() {
        // return this.httpClient.get<CustomMerchantList[]>(`${this.baseUrl.getUrlExperience()}/merchant/custom`,
        //     { headers: this.headers });
        return this.httpClient.get<CustomMerchantList[]>(`${this.baseUrl.getUrlExperience()}merchant/api/merchant/custom`,
            { headers: this.headers });
    }

    listarComerciosPorGrupo(merchantId: number): Observable<GroupBean[]> {
        return this.httpClient.get<GroupBean[]>(`${this.baseUrl.getUrlExperience()}merchant/api/merchant/${merchantId}/group`,);
    }

    listarComerciosPorGrupoId(merchantId: number): Observable<any> {
        return this.httpClient.get<any[]>(`${this.baseUrl.getUrlExperience()}merchant/api/merchant/${merchantId}/group`,);
    }



    getMerchantById(merchantId: number) {
        return this.httpClient.get<MerchantAllResponse>(`${this.baseUrl.getUrlComercios()}/merchant/find/${merchantId}`, { headers: this.headers });
    }

    updateDirectConnection(body:ConfigurationProcessorRequest[],merchantId: number) {
        return this.httpClient.put<void>(`${this.baseUrl.getUrlComercios()}/merchant/${merchantId}/processor-banks/config`,body, { headers: this.headers });
    }

    getAllCurrencies() {
        return this.httpClient.get<Currency[]>(`${this.baseUrl.getUrlComercios()}/currency`, { headers: this.headers });
    }

    getAllCountries() {
        return this.httpClient.get<Country[]>(`${this.baseUrl.getUrlComercios()}/country`, { headers: this.headers });
    }

    getAllProcessors(processorStatus?: string) {
        if (processorStatus) {
            return this.httpClient.get<Processor[]>(`${this.baseUrl.getUrlComercios()}/processor?status=${processorStatus}`, { headers: this.headers });
        }

        return this.httpClient.get<Processor[]>(`${this.baseUrl.getUrlComercios()}/processor`, { headers: this.headers });
    }

    createMerchant(createMerchantRequest: MerchantRequest) {
        return this.httpClient.post<MerchantAllResponse>(
            `${this.baseUrl.getUrlComercios()}/merchant`,
            createMerchantRequest,
            { headers: this.headers }
        );
    }

    updateMerchant(updateMerchantRequest: MerchantRequest, merchantId: number) {
        return this.httpClient.put<MerchantAllResponse>(`${this.baseUrl.getUrlComercios()}/merchant/${merchantId}`, updateMerchantRequest, { headers: this.headers });
    }

    deleteMerchant(merchantId: number) {
        return this.httpClient.delete<void>(`${this.baseUrl.getUrlComercios()}/merchant/${merchantId}`, { headers: this.headers })
    }

    getAllMerchantsRates() {
        return this.httpClient.get<MerchantRateList[]>(`${this.baseUrl.getUrlMerchantRate()}/commission`, { headers: this.headers });
    }

    getMerchantRates(merchantId: number) {
        return this.httpClient.get<MerchantRateList[]>(`${this.baseUrl.getUrlMerchantRate()}/merchant/${merchantId}`, { headers: this.headers });
    }

    addMerchantRate(addMerchantRateRequest: MerchantRateRequest) {
        return this.httpClient.post<MerchantAllResponse>(`${this.baseUrl.getUrlMerchantRate()}/commission`, addMerchantRateRequest, { headers: this.headers });
    }

    updateMerchantRate(updateMerchantRateRequest: MerchantRateRequest, merchantId: number) {
        return this.httpClient.put<MerchantAllResponse>(`${this.baseUrl.getUrlMerchantRate()}/commission`, updateMerchantRateRequest, { headers: this.headers });
    }

    getAllMerchantGroups(): Observable<MerchantGroup[]> {
        return this.httpClient.get<MerchantGroup[]>(`${this.baseUrl.getUrlComercios()}/group`, { headers: this.headers });
    }

    getMerchantGroupById(groupId: number): Observable<MerchantGroup> {
        return this.httpClient.get<MerchantGroup>(`${this.baseUrl.getUrlComercios()}/group/${groupId}`, { headers: this.headers });
    }

    createMerchantGroup(merchantGroupRequest: MerchantGroup): Observable<MerchantGroup> {
        return this.httpClient.post<MerchantGroup>(`${this.baseUrl.getUrlComercios()}/group`, merchantGroupRequest, { headers: this.headers });
    }

    updateMerchantGroup(merchantId: number, merchantGroupRequest: MerchantGroup): Observable<MerchantGroup> {
        return this.httpClient.put<MerchantGroup>(`${this.baseUrl.getUrlComercios()}/group/${merchantId}`, merchantGroupRequest, { headers: this.headers });
    }


    listarComercios(): Observable<MerchantBean[]> {
        return this.httpClient.get<MerchantBean[]>(this.baseUrl.getUrlComercios() + "/merchant/custom", { headers: this.headers });
    }

    obtenerComercioPorId(id: string): Observable<MerchantBean> {
        return this.httpClient.get<MerchantBean>(`${this.baseUrl.getUrlComercios()}/merchant/${id}`, { headers: this.headers });    
    }

    obtenerDetalleComercioPorId(id: string): Observable<MerchantBean> {
        return this.httpClient.get<MerchantBean>(`${this.baseUrl.getUrlComercios()}/merchant/find/${id}`, { headers: this.headers });
    }

    actualizarKeys(merchantKeyRequest: MerchantKeyRequest, id: string): Observable<MerchantBean> {
        return this.httpClient.patch<MerchantBean>(`${environment.urlApiMsMerchantBack}/merchant/${id}/key`, merchantKeyRequest, { headers: this.headers });
    }

    getRouteRulesByMerchant(id: string): Observable<MerchantRouteRuleBean[]> {
        return this.httpClient.get<MerchantRouteRuleBean[]>(`${this.baseUrl.getUrlProcessorRouting()}/merchant-rules/${id}`, { headers: this.headers });
    }

    getRouteRulesByMerchantAndRule(id: string, ruleId: number): Observable<MerchantRouteRuleBean> {
        return this.httpClient.get<MerchantRouteRuleBean>(`${this.baseUrl.getUrlProcessorRouting()}/merchant-rules/${id}/rule/${ruleId}`, { headers: this.headers });
    }

    modifyMerchantRouteRules(merchantRulesRequest: ModifyMerchantRouteRuleRequest, id: string): Observable<GenericBean> {
        return this.httpClient.post<GenericBean>(`${this.baseUrl.getUrlProcessorRouting()}/merchant-rules/${id}`, merchantRulesRequest, { headers: this.headers });
    }

    getMasterByCode(masterCode: string): Observable<MasterParametersBean> {
        return this.httpClient.get<MasterParametersBean>(`${this.baseUrl.getUrlMasterParameters()}/find/${masterCode}`, { headers: this.headers });
    }

    getRouteRuleMethodsByMerchant(id: string): Observable<MerchantRouteRuleMethodBean[]> {
        return this.httpClient.get<MerchantRouteRuleMethodBean[]>(`${this.baseUrl.getUrlProcessorRouting()}/merchant-rules-method/${id}`, { headers: this.headers });
    }

    getRouteRuleMethodsByIdentifier(id: string, ruleMethodId: string): Observable<MerchantRouteRuleMethodBean> {
        return this.httpClient.get<MerchantRouteRuleMethodBean>(`${this.baseUrl.getUrlProcessorRouting()}/merchant-rules-method/${id}/rule-method/${ruleMethodId}`, { headers: this.headers });
    }

    modifyMerchantRouteMethod(request: ModifyMerchantRouteMethodRequest[], id: string): Observable<GenericBean> {
        return this.httpClient.post<GenericBean>(`${this.baseUrl.getUrlProcessorRouting()}/merchant-rules-method/${id}`, request, { headers: this.headers });
    }

    modifyMerchantRouteMethodConfig(request: Settings[], id: string, ruleMethodId: string): Observable<GenericBean> {
        return this.httpClient.post<GenericBean>(`${this.baseUrl.getUrlProcessorRouting()}/merchant-method-config/${id}/rule-method/${ruleMethodId}`, request, { headers: this.headers });
    }

    getProcessorsByCountryAndMethod(country: string, method: string) {
        let queryParams = new HttpParams()
        queryParams = queryParams.append('country', country)
        queryParams = queryParams.append('method', method)
        return this.httpClient.get<Processor[]>(`${this.baseUrl.getUrlComercios()}/processor/find`, { headers: this.headers, params: queryParams });
    }

    findAllMerchantMultiprocessorPaymentMethods(merchantId: number): Observable<IFindAllMerchantMultiprocessorPaymentMethodsResponse[]> {
        return this.httpClient.get<IFindAllMerchantMultiprocessorPaymentMethodsResponse[]>(`${environment.urlApiMsExperience}multiprocessor/api/merchant-configuration/all-payment-methods/${merchantId}`, { headers: this.headers });
    }

    findAllMerchantMultiprocessorConfig(params: { merchantId: number, paymentMethod: string, country: string }): Observable<IFindAllMerchantMultiprocessorConfig[]> {
        return this.httpClient.get<IFindAllMerchantMultiprocessorConfig[]>(`${environment.urlApiMsExperience}multiprocessor/api/merchant-configuration/all-collector-processors/${params.merchantId}?method=${params.paymentMethod}&country=${params.country}`, { headers: this.headers });
    }

    updateMerchantMultiprocessorPaymentMethods(merchantId: number, paymentMethods: { paymentMethod: string }[]): Observable<{ messageCode: string }> {
        return this.httpClient.post<{ messageCode: string }>(`${environment.urlApiMsExperience}multiprocessor/api/merchant-configuration/all-payment-methods/${merchantId}`, paymentMethods, { headers: this.headers });
    }

    updateMerchantMultiprocessorConfig(merchantId: number, paymentMethod: string, config: { configId: number, showOrder: number }[]): Observable<{ messageCode: string }> {
        return this.httpClient.post<{ messageCode: string }>(`${environment.urlApiMsExperience}multiprocessor/api/merchant-configuration/all-collector-processors/${merchantId}?method=${paymentMethod}`, config, { headers: this.headers });
    }


    getPaymentsMethods(): Observable<string[]> {
        return this.httpClient.get<string[]>(`${this.baseUrl.getUrlMasterParameters()}/paymentMethods`, { headers: this.headers });
    }
}
